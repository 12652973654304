//班车批次列表接口
const getBusBatchList = `/gateway/hc-edu/shuttleBusOrder/shuttleBusBatchList`;
//新增班车批次接口
const addBusBatchList = `/gateway/hc-edu/shuttleBusOrder/addShuttleBusBatch`;
//编辑班车批次接口
const editBusBatchList = `/gateway/hc-edu/shuttleBusOrder/compileShuttleBusBatch`;
//班车批次详情接口
const getBusBatchListDetail = `/gateway/hc-edu/shuttleBusOrder/shuttleBusBatchDetails`;
//删除班车批次接口
const deleteShuttleBusBatch = `/gateway/hc-edu/shuttleBusOrder/deleteShuttleBusBatch`;
//社区租户列表接口
const getTenantList = `/gateway/blade-system/tenant/getDTenantList`;
// 获取社区列表
const getDTenantList = `/gateway/blade-system/tenant/getDTenantListWithRight`;
export {
  getBusBatchList,
  addBusBatchList,
  editBusBatchList,
  getBusBatchListDetail,
  deleteShuttleBusBatch,
  getTenantList,
  getDTenantList
};
