<template>
  <div class="entrustChildForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        v-show="form.batchId"
        label="批次ID"
        :rules="[{ required: false, message: '请输入批次ID', trigger: 'blur' }]"
        prop="batchId"
      >
        <v-input disabled v-model="form.batchId" :width="width"></v-input>
      </el-form-item>
      <el-form-item
        label="批次时间"
        :rules="[
          { required: true, message: '请选择批次结束时间', trigger: 'blur' },
        ]"
        prop="endTime"
      >
        <v-date-picker
          clearable
          :width="width"
          v-model="batchTime"
          formatValue="yyyy-MM-dd"
          startPlaceholder="批次开始时间"
          endPlaceholder="批次结束时间"
        />
      </el-form-item>

      <el-form-item
        label="报名时间"
        :rules="[
          { required: true, message: '请选择报名时间', trigger: 'change' },
        ]"
        prop="applyEndTime"
      >
        <v-date-picker
          clearable
          type="datetimerange"
          :width="width"
          v-model="createTime"
          formatValue="yyyy-MM-dd,HH:mm:ss"
          startPlaceholder="报名开始时间"
          endPlaceholder="报名结束时间"
        />
      </el-form-item>

      <el-form-item
        label="所属租户"
        :rules="[
          { required: true, message: '请选择所属租户', trigger: 'blur' },
        ]"
        prop="tenantId"
      >
        <v-select
          placeholder="请选择所选租户"
          :clearable="isClearable"
          :options="tenantList"
          v-model="form.tenantId"
          :width="width"
        />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import {
  addBusBatchList,
  editBusBatchList,
  getBusBatchListDetail,
  getTenantList,
  getDTenantList,
} from "./api.js";

export default {
  name: "partyMemberForm",
  data() {
    return {
      width: 400,
      submitConfig: {
        queryUrl: getBusBatchListDetail,
        submitUrl: "",
      },

      form: {
        batchId: "", // 批次id
        startTime: "", // 开始时间
        endTime: "", //结束时间
        applyBeginTime: "", //可报名开始时间
        applyEndTime: "", // 可报名结束时间
        tenantId: "", // 租户ID
      },
      tenantList: [],
      isClearable: false,
    };
  },
  computed: {
    createTime: {
      get() {
        return !this.form.applyBeginTime && !this.form.applyEndTime
          ? []
          : [this.form.applyBeginTime, this.form.applyEndTime];
      },
      set(val) {
        [this.form.applyBeginTime, this.form.applyEndTime] = val || ["", ""];
      },
    },
    batchTime: {
      get() {
        return !this.form.startTime && !this.form.endTime
          ? []
          : [this.form.startTime, this.form.endTime];
      },
      set(val) {
        [this.form.startTime, this.form.endTime] = val || ["", ""];
      },
    },
  },
  mounted() {
    this.getDTenantList();
    const { id } = this.$route.query;
    this.form.batchId = id;
    this.submitConfig.submitUrl = id ? editBusBatchList : addBusBatchList;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ batchId: id });
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    //获取社区列表
    async getDTenantList() {
      const res = await this.$axios.get(getDTenantList);
      if (res.code == 200) {
        const { data } = res;
        data.forEach((item) => {
          item.value = item.tenantId;
          item.label = item.districtName;
        });

        this.tenantList = data;
        if (this.tenantList.length > 1) {
          this.form.tenantId = null;
          this.isClearable = true;
        } else {
          this.form.tenantId = this.tenantList[0].tenantId;
          this.isClearable = false;
        }
      }
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key] || this.form[key];
      });
    },
    // getTenantList() {
    //   let params = {
    //     tenantId: "",
    //     tenantName: "",
    //   };
    //   this.tenantList = [];
    //   this.$axios.get(`${getTenantList}`, { params }).then((res) => {
    //     if (res.code === 200) {
    //       res.data.forEach((item) => {
    //         let obj = {};
    //         obj.label = item.tenantName;
    //         obj.value = item.tenantId;
    //         this.tenantList.push(obj);
    //       });
    //     } else {
    //     }
    //   });
    // },
    submitBefore() {
      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>

<style lang="less" scoped>
.entrustChildForm {
  box-sizing: border-box;
  height: 100%;

  .areaTimes {
    display: flex;
    align-items: flex-end;

    padding: 20px 0;
    border-bottom: 1px solid #eee;
    .areaTimes-l {
      margin-right: 20px;
    }
    .areaTimes-r {
      height: 57px;
      /deep/ .v-button {
        margin-right: 10px;
      }
    }
    .item {
      display: flex;
      align-content: center;
      margin: 20px 0;
      .item-l {
        margin-right: 10px;
      }
      .item-r {
        display: flex;
      }
    }
  }
}
</style>
